.about-project__title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.21;
  letter-spacing: -0.04em;
  margin: 0;
}

@media screen and (max-width: 429px) {
  .about-project__title {
    font-size: 18px;
    line-height: 1.32;
    padding-bottom: 16px;
  }
}
