.techs__used-techs {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-inline-start: 0;
  margin: 100px 0 0;
}

.techs__used-techs li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 60px;
  border-radius: 10px;
  background-color: #e8e8e8;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  letter-spacing: -0.04em;
  transition: 0.3s;
  cursor: default;
}

.techs__used-techs li:nth-child(1n):not(:last-child) {
  margin-right: 10px;
}

.techs__used-techs li:nth-child(1):hover {
  background-color: #ff6838;
  color: #fff;
}

.techs__used-techs li:nth-child(2):hover {
  background-color: #005a9c;
  color: #fff;
}

.techs__used-techs li:nth-child(3):hover {
  background-color: #f7e01d;
  color: #fff;
}

.techs__used-techs li:nth-child(4):hover {
  background-color: #61dafb;
  color: #fff;
}

.techs__used-techs li:nth-child(5):hover {
  background-color: #24292f;
  color: #fff;
}

.techs__used-techs li:nth-child(6):hover {
  background-color: #4d4d4d;
  color: #fff;
}

.techs__used-techs li:nth-child(7):hover {
  background-color: #13aa52;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .techs__used-techs {
    margin-top: 83px;
  }
}

@media screen and (max-width: 680px) {
  .techs__used-techs {
    display: grid;
    grid-template-rows: 57px 57px;
    grid-template-columns: 84px 84px 84px 84px;
    gap: 10px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 429px) {
  .techs__used-techs {
    display: grid;
    grid-template-columns: 84px 84px;
    column-gap: 10px;
    gap: 10px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 429px) {
  .techs__used-techs li:nth-child(1n):not(:last-child) {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .techs__used-techs li {
    width: 84px;
    height: 57px;
    font-size: 12px;
    font-weight: 1.21;
  }
}
