.header__head_only-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 396px;
  margin: 70px auto 74px;
}

.header__head_only-logo .header__logo {
  margin: 0;
}

@media screen and (max-width: 465px) {
  .header__head_only-logo {
    margin-top: 56px;
    margin-bottom: 123px;
    align-items: center;
  }
}
