.search-form__input {
  max-width: 1086px;
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  border: none;
  text-indent: 15px;
  margin-right: 10px;
  border-radius: 8px;
  padding-left: 5px;
}

.search-form__input:focus {
  outline: none;
  border: 1px #a0a0a0 dashed;
  text-indent: 14px;
}

.search-form__input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28;
  color: #a0a0a0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .search-form__input {
    padding-left: 0;
  }
}

@media screen and (max-width: 429px) {
  .search-form__input {
    height: 44px;
    text-indent: 12px;
  }
}
