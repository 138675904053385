.search-form__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  .search-form__form {
    padding-top: 80px;
  }
}
