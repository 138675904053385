.promo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #465dff;
  height: 560px;
}

@media screen and (max-width: 768px) {
  .promo {
    height: 844px;
  }
}

@media screen and (max-width: 429px) {
  .promo {
    height: 630px;
  }
}
