.more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 166px;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1226px) {
  .more {
    max-width: 708px;
  }
}

@media screen and (max-width: 767px) {
  .more {
    max-width: 300px;
  }
}
