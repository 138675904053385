.notification-popup__message_signin-signup {
  visibility: hidden;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.21;
  color: #ee3465;
  text-align: center;
  margin: 0;
  border-radius: 5px;
  position: absolute;
  top: 284px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  height: 51px;
}
