.about-project__scale-backend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 36px;
  margin-bottom: 14px;
  background-color: #2be080;
}

@media screen and (max-width: 429px) {
  .about-project__scale-backend {
    height: 36px;
    margin-bottom: 10px;
  }
}
