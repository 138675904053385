.portfolio {
  padding-top: 100px;
}

@media screen and (max-width: 768px) {
  .portfolio {
    padding-top: 90px;
  }
}

@media screen and (max-width: 429px) {
  .portfolio {
    padding-top: 69px;
  }
}
