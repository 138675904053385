.header__link {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28;
}

.header__link:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  display: block;
  width: 100%;
  height: 1px;
  transition: width 0.5s;
  background-color: #2be080;
}

.header__link::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -3px;
  display: block;
  width: 0;
  height: 1px;
  background-color: #2be080;
  transition: width 0.5s;
}

.header__link:link {
  text-decoration: none;
}

.header__link:visited {
  color: black;
}
