.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 465px) {
  .form {
    height: 468px;
  }
}
