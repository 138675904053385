.movies-card {
  width: 364px;
  height: 271px;
  margin: 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 1226px) {
  .movies-card {
    width: 339px;
    height: 258px;
  }
}

@media screen and (max-width: 767px) {
  .movies-card {
    width: 300px;
    height: 236px;
  }
}
