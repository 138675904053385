.promo__title {
  font-size: 50px;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.04em;
  text-align: center;
  max-width: 730px;
  height: 116px;
  margin: 0;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .promo__title {
    margin-left: 19px;
    margin-right: 19px;
  }
}

@media screen and (max-width: 429px) {
  .promo__title {
    font-size: 31px;
    line-height: 1.25;
    max-width: 300px;
    margin-bottom: 48px;
  }
}
