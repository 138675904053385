.page-not-found__title {
  font-size: 140px;
  font-weight: 400;
  line-height: 1.21;
  margin: 246px 0 5px;
}

@media screen and (max-width: 429px) {
  .page-not-found__title {
    font-size: 80px;
    margin: 329px 0 10px;
  }
}
