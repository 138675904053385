.header {
  background-color: #465dff;
  padding: 0 70px;
}

@media screen and (max-width: 815px) {
  .header {
    padding: 0 30px;
  }
}

@media screen and (max-width: 429px) {
  .header {
    padding: 0 14px;
  }
}
