.footer__nav {
  display: flex;
  list-style: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.21;
  margin: 0;
  padding-inline-start: 0;
}

.footer__nav li:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

.footer__nav a:visited {
  color: #000;
}

.footer__nav a:link {
  color: #000;
  text-decoration: none;
}

.footer__nav a:active {
  color: #000;
}

.footer__nav a:focus {
  color: #000;
}

.footer__nav li:nth-child(1n):not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 429px) {
  .footer__nav {
    flex-direction: column;
    font-size: 12px;
    line-height: 1.25;
  }
}

@media screen and (max-width: 429px) {
  .footer__nav li:nth-child(1n):not(:last-child) {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
