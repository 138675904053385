.page {
  font-family: "Inter", Arial, sans-serif;
  background-color: #fff;
  margin: auto;
  min-height: 100%;
}

html,
body {
  height: 100%;
}

body #root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
