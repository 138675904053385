.more__button-more {
  display: none;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.21;
  padding: 0;
  border: none;
  transition: 0.5s;
  height: 36px;
  margin: 50px auto 80px;
  background-color: #f9f9f9;
}

.more__button-more:hover {
  cursor: pointer;
  background-color: #ff6838;
}
