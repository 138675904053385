.about-me__contacts {
  display: flex;
  list-style: none;
  font-size: 14px;
  line-height: 1.21;
  font-weight: 500;
  padding-inline-start: 0;
  margin-top: 99px;
  margin-bottom: 0;
  cursor: pointer;
  color: #000;
}

.about-me__contacts li:hover {
  text-decoration: gray underline;
}

.about-me__contacts a:visited {
  color: #000;
  cursor: pointer;
}

.about-me__contacts a:link {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.about-me__contacts li:nth-child(1n):not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .about-me__contacts {
    margin-top: 87px;
  }
}

@media screen and (max-width: 429px) {
  .about-me__contacts {
    margin-top: 40px;
  }
}
