.about-me__photo {
  max-width: 320px;
  max-height: 240px;
  border-radius: 10px;
}

@media screen and (max-width: 650px) {
  .about-me__photo {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 429px) {
  .about-me__photo {
    width: 100%;
    height: 100%;
  }
}
