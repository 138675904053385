.promo__button-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  background-color: #e8e8e8;
  width: 96px;
  height: 36px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  border: none;
  transition: 0.3s;
  cursor: pointer;
}

.promo__button-info:hover {
  background-color: #2be080;
}

@media screen and (max-width: 429px) {
  .promo__button-info {
    width: 90px;
    height: 30px;
    font-size: 10px;
    line-height: 1.6;
  }
}
