.portfolio__title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.41;
  color: #a0a0a0;
  margin: 0 0 30px;
}

@media screen and (max-width: 429px) {
  .portfolio__title {
    font-size: 14px;
    line-height: 1.42;
    margin-bottom: 40px;
  }
}
