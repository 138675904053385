.search-form__label-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}

@media screen and (max-width: 429px) {
  .search-form__label-name {
    font-size: 11px;
    line-height: 1.63;
  }
}
