.form__signin-signup-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  color: #ff6838;
  text-decoration: none;
}

.form__signin-signup-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 465px) {
  .form__signin-signup-link {
    font-size: 12px;
  }
}
