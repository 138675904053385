.profile {
  max-width: 410px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 74px auto 70px;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .profile {
    margin-top: 236px;
  }
}

@media screen and (max-width: 470px) {
  .profile {
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 429px) {
  .profile {
    margin-top: 70px;
    margin-bottom: 0;
  }
}
