.navigation-menu__button-close {
  position: absolute;
  top: 27px;
  right: 27px;
  background-image: url('./../../../images/button_close_icon.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
  width: 22px;
  height: 22px;
  border: none;
  cursor: pointer;
  transition: 0.6s;
}

.navigation-menu__button-close:hover {
  transform: rotate(180deg);
}

@media screen and (max-width: 429px) {
  .navigation-menu__button-close {
    right: 10px;
  }
}
