.techs {
  min-height: 625px;
  padding: 100px 70px 0;
  background-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .techs {
    min-height: 567px;
    padding: 90px 50px 0;
    background-color: #f5f5f5;
  }
}

@media screen and (max-width: 429px) {
  .techs {
    min-height: 651px;
    padding: 70px 18px 0;
    background-color: #fff;
  }
}
