.navigation-menu__list-link_active a {
  padding-bottom: 4px;
  border-bottom: 2px black solid;
}

@media screen and (max-width: 596px) {
  .navigation-menu__list-link_active a {
    padding-bottom: 7px;
    border-bottom: 2px black solid;
  }
}
