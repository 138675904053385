.signup__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.21;
  margin: 38px 0 0;
}

@media screen and (max-width: 465px) {
  .signup__title {
    margin-top: 47px;
  }
}
