.portfolio__works {
  list-style: none;
  padding-inline-start: 0;
}

.portfolio__works li {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: -0.04em;
  border-bottom: 1px #e8e8e8 solid;
  padding: 20px 0;
}

.portfolio__works a:hover {
  cursor: pointer;
  background: #e8e8e8;
}

.portfolio__works li:last-child {
  border: none;
}

.portfolio__works li::after {
  content: "";
  background-image: url("./../../../../images/arrow-to-corner.svg");
  background-repeat: no-repeat;
  padding-right: 27px;
  background-position: 50% 50%;
}

@media screen and (max-width: 768px) {
  .portfolio__works li {
    font-size: 28px;
    line-height: 1.78;
  }
}

@media screen and (max-width: 650px) {
  .portfolio__works li {
    font-size: 22px;
    line-height: 1.71;
  }
}

@media screen and (max-width: 429px) {
  .portfolio__works li {
    font-size: 18px;
    line-height: 1.55;
  }
}

@media screen and (max-width: 429px) {
  .portfolio__works li::after {
    content: "";
    background-image: url("./../../../../images/arrow-to-corner.svg");
    background-repeat: no-repeat;
    background-size: 11px;
    padding-right: 20px;
    background-position: 50% 50%;
  }
}

.portfolio__works a:link {
  color: #000;
  text-decoration: none;
}

.portfolio__works a:visited {
  color: #000;
}
