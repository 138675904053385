.signup {
  max-width: 396px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 465px) {
  .signup {
    padding: 0 30px;
  }
}
