.title-description {
  font-size: 50px;
  font-weight: 400;
  line-height: 1.16;
  letter-spacing: -0.04em;
}

.Techs .title-description {
  margin: 90px 0 0;
}

.AboutMe .title-description {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .Techs .title-description {
    margin-top: 80px;
  }
}

@media screen and (max-width: 429px) {
  .Techs .title-description {
    font-size: 30px;
    line-height: 1.21;
    margin-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  .AboutMe .title-description {
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
  }
}

@media screen and (max-width: 429px) {
  .AboutMe .title-description {
    font-size: 30px;
    line-height: 1.21;
  }
}
