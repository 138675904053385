.footer__date {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.21;
  margin: 0;
}

@media screen and (max-width: 429px) {
  .footer__date {
    font-size: 12px;
    margin-top: 30px;
    color: #a0a0a0;
  }
}
