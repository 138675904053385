.profile__input {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.21;
  text-align: right;
  max-width: 80%;
  width: 100%;
  height: 14px;
  border: none;
  padding: 0;
  transition: 0.2s;
}

.profile__input:hover {
  box-shadow: 0 0 2px gray;
}

.profile__input:focus {
  outline: none;
  box-shadow: 0 0 2px gray;
}
