.navigation-menu__list-link {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.21;
  text-decoration: none;
  color: black;
}

.navigation-menu__list-link a:hover {
  padding-bottom: 4px;
  border-bottom: 2px black solid;
}

@media screen and (max-width: 596px) {
  .navigation-menu__list-link a:hover {
    padding-bottom: 7px;
    border-bottom: 2px black solid;
  }
}
