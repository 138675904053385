.notification-popup {
  visibility: hidden;
  align-items: center;
  text-align: center;
  max-width: 300px;
  width: 100%;
  min-height: 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.21;
  top: 25px;
  right: 0px;
  position: fixed;
  border-radius: 5px;
  cursor: pointer;
  /* visibility: hidden;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.21;
  text-align: center;
  margin: 0;
  border-radius: 5px;
  position: absolute;
  top: 284px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  height: 51px; */
}
