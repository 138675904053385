.movies-card-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0;
  flex: 1 0 auto;
}

@media screen and (max-width: 429px) {
  .movies-card-list {
    margin-top: 50px;
  }
}
