.form__reg-question {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21;
  color: #a0a0a0;
  margin: 0 6px 0 0;
}

@media screen and (max-width: 465px) {
  .form__reg-question {
    font-size: 12px;
  }
}
