.movies-card-list__grid {
  display: none;
  grid-template-columns: repeat(auto-fit, 364px 364px 364px);
  grid-template-rows: repeat(auto-fit, 271px 271px 271px) F;
  row-gap: 30px;
  column-gap: 24px;
}

@media screen and (max-width: 1226px) {
  .movies-card-list__grid {
    grid-template-columns: 339px 339px;
    grid-template-rows: 258px 258px;
    row-gap: 36px;
    column-gap: 30px;
  }
}

@media screen and (max-width: 767px) {
  .movies-card-list__grid {
    grid-template-columns: 300px;
    grid-template-rows: 236px;
    row-gap: 16px;
  }
}
