.about-me__about {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  margin: 18px 0 0;
}

@media screen and (max-width: 768px) {
  .about-me__about {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 16px;
  }
}

@media screen and (max-width: 429px) {
  .about-me__about {
    font-size: 11px;
    line-height: 1.45;
    margin-top: 20px;
  }
}
