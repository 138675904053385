.navigation-menu__list {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
}

.navigation-menu__list a {
  text-decoration: none;
  color: black;
}

.navigation-menu__list li:not(:last-child) {
  margin-bottom: 28px;
}

@media screen and (max-width: 596px) {
  .navigation-menu__list li:not(:last-child) {
    margin-bottom: 25px;
  }
}
