.profile__hello-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.21;
  margin: 0 0 123px;
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .profile__hello-title {
    margin-bottom: 96px;
    max-width: 410px;
  }
}

@media screen and (max-width: 429px) {
  .profile__hello-title {
    margin-bottom: 81px;
  }
}
