.profile__logout {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.21;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: -2px auto 0;
  color: #ee3465;
}

.profile__logout:hover {
  text-decoration: underline;
}

@media screen and (max-width: 429px) {
  .profile__logout {
    font-size: 12px;
    margin-top: 2px;
  }
}
